import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "register-service-worker";
import "assets/css/base.scss"; /**所有样式文件的入口 */
import PointTitle from "components/PointTitle.vue";
import { lazyLoadImage } from "util/directive";
import lang from "element-plus/lib/locale/lang/zh-cn";
import "dayjs/locale/zh-cn";
import locale from "element-plus/lib/locale";

import {
  ElMenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElSubmenu,
  ElBacktop,
  ElSkeleton,
  ElSkeletonItem,
  ElPagination,
} from "element-plus";
locale.use(lang);

const app = createApp(App);
app.component("PointTitle", PointTitle);
app.component(ElMenu.name, ElMenu);
app.component(ElMenuItem.name, ElMenuItem);
app.component(ElMenuItemGroup.name, ElMenuItemGroup);
app.component(ElSubmenu.name, ElSubmenu);
app.component(ElBacktop.name, ElBacktop);
app.component(ElSkeleton.name, ElSkeleton);
app.component(ElSkeletonItem.name, ElSkeletonItem);
app.component(ElPagination.name, ElPagination);
app.directive("lazyLoadImage", lazyLoadImage);
app.use(router).mount("#app");
