import request from "util/request";
import { API_URL } from "./base_url";
export const getNewssListApi = function (data) {
  return request.post(API_URL, 302, data).then((data) => {
    // data?.data?.sort(
    //   (a, b) => new Date(b.update_time) - new Date(a.update_time)
    // );
    return data;
  });
};
export const checkNewsContentApi = function (data) {
  return request.post(API_URL, 305, data);
};
