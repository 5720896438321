<template>
  <div class="news-contack-box">
    <div :class="loading ? 'min-height' : ''">
      <iframe class="contact-ifream" ref="ifream" scrolling="no"></iframe>
    </div>
    <div class="call-back-button" @click="goBack">返回</div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { checkNewsContentApi } from "api/new";
export default {
  name: "NewsContact",
  props: ["newsId"],
  setup(props, { emit }) {
    const dataList = ref([]);
    const loading = ref(true);
    const html = computed(
      () => dataList.value.find((item) => item.id == props.newsId)?.content
    );
    const ifream = ref(null);

    // 将html内容渲染到ifream中
    watch(html, () => {
      // console.log(html, "html");
      let ifreamDocument = ifream.value.contentDocument;
      let body = ifreamDocument.body;
      body.innerHTML = html.value;

      let sciprt = ifreamDocument.createElement("script");
      sciprt.innerHTML = `
      var imgs = document.getElementsByTagName("img");
      let imgFlag = 0;
      [...imgs].forEach((img) => {
        img.style.height == "100%" ? (img.style.height = "") : "";
        const loadingOk = () => {top.window.setIfreamSize();console.log('图片全部加载完毕')}
        let imgLoad = () => ++imgFlag == imgs.length && loadingOk();
        img.onload = imgLoad;
        img.onerror = imgLoad;
      });
      var tags = document.getElementsByTagName('*');
      [...tags].forEach(item=>{
        if([...item.style.width].includes("%")){
          if(item.style.width.replace("%","")>100){
            item.style.width = '100%'
          }
        }
      })
      //利用ifream回调函数让父窗口设置ifream的高度
      setTimeout(() => top.window.setIfreamSize());
      `;
      body.append(sciprt);

      window.setIfreamSize = () => {
        loading.value = false;
        ifream.value.style.height = body.offsetHeight + 10 + "px";
        body.style.overflow = "hidden";
      };
    });

    async function getNewsItem() {
      const data = await checkNewsContentApi({ id: props.newsId });
      dataList.value = data.data;
    }
    getNewsItem();

    const goBack = () => emit("close");

    return { loading, ifream, goBack };
  },
};
</script>

<style scoped lang="scss">
.news-contack-box {
  position: relative;
  .contact-ifream {
    width: 100%;
  }
  .min-height {
    min-height: 800px;
  }
  .call-back-button {
    position: absolute;
    right: 20px;
    cursor: pointer;
    top: -40px;
    font-weight: 700;

    &:hover {
      color: #167eb5;
    }
  }
}
</style>
