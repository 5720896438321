let flag = true;

export const lazyLoadImage = {
  mounted(el, arg) {
    el.src = arg.value;

    // el.onload = function () {
    // };
    // console.log(el, arg);
    // el.onload = function () {
    //   if (flag) {
    //     el.src = arg.value;
    //   } else {
    //     flag = false;
    //   }
    //   console.log(flag);
    // };
    // console.log(el)
  },
};
