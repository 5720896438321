<template>
  <div class="contact-title"><i class="title-icon" />{{ title }}</div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "标题",
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-title {
  font-size: 22px;
  font-weight: 700;
  display: flex;
  align-items: center;
  // font-family: Alibaba-PuHuiTi-Bold;
  font-weight: 700;

  .title-icon {
    width: 9px;
    height: 9px;
    display: inline-block;
    border-radius: 9px;
    background: linear-gradient(90deg, #0070c0 0%, #acde68 100%);
    margin: 0 10px 0 0;
  }
}
</style>
