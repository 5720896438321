<template>
  <ul class="news-policy-box" v-if="!showContact">
    <!-- 骨架屏 -->
    <el-skeleton
      style="width: 100%"
      class="skeleton-box"
      v-for="item in pageSize"
      :key="item"
      :loading="loading"
      animated
    >
      <template #template>
        <el-skeleton-item variant="image" style="width: 289px; height: 178px" />
        <div style="margin-left: 20px; flex: 1">
          <el-skeleton-item variant="text" />
          <el-skeleton-item
            variant="text"
            style="width: 30%; margin-top: 20px"
          />
          <div>
            <el-skeleton-item
              variant="text"
              style="width: 100%; margin-top: 20px"
            /><el-skeleton-item
              variant="text"
              style="width: 100%; margin-top: 20px"
            /><el-skeleton-item
              variant="text"
              style="width: 100%; margin-top: 20px"
            />
          </div>
        </div>
      </template>
    </el-skeleton>

    <li v-for="item in dataList" :key="item">
      <img class="image" :src="item.thumb" :alt="item.title" />
      <div class="contact">
        <h3 @click="toNewsContact(item.id)">
          {{ item.title }}
        </h3>
        <p class="time-step">{{ item.update_time }}</p>
        <p class="text-contact">
          {{ item.name }}
        </p>
      </div>
    </li>

    <div class="pagination-box">
      <el-pagination
        layout="total,prev, pager, next, jumper"
        :total="total"
        :page-size="pageSize"
        v-model:currentPage="currentPage"
      >
      </el-pagination>
    </div>
  </ul>

  <news-contact
    :news-id="newsid"
    :type="props.type"
    v-else
    @close="() => (showContact = false)"
  />
</template>

<script>
import { getNewssListApi } from "api/new";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import NewsContact from "./NewsContact";
import { goToPageTop } from "util";
import { nextTick } from "process";
export default {
  name: "Academic",
  props: ["type"],
  components: {
    NewsContact,
  },
  setup(props) {
    /** 分页数据 */
    const pageSize = ref(5);
    const total = ref(0);
    const currentPage = ref(1);

    //**列表数据 */
    const dataList = ref([]);
    //**列表loading标识*/
    const loading = ref(true);

    async function getNewsList() {
      dataList.value = [];
      loading.value = true;
      const data = await getNewssListApi({
        type: props.type,
        pageNum: currentPage.value,
        pageSize: pageSize.value,
      });
      total.value = Number(data?.data.total);
      dataList.value = data?.data?.data;
      loading.value = false;
    }
    getNewsList();
    /**根据分页加载列表 */
    watch(currentPage, () => getNewsList());

    /**内容组件数据 */
    const newsid = ref(0);
    /**是否打开内容组件 */
    const showContact = ref(false);
    const toNewsContact = (newsId) => {
      newsid.value = newsId;
      showContact.value = true;
      nextTick(() => goToPageTop(580));
    };
    const closeContent = () => (showContact.value = false);
    // 根据路由的参数做一些事情
    const route = useRoute();
    if (route.query.isHome) {
      goToPageTop();
    }
    if (route.query.newsId) {
      toNewsContact(route.query.newsId);
    }

    return {
      getNewsList,
      dataList,
      toNewsContact,
      loading,
      newsid,
      showContact,
      closeContent,
      props,
      pageSize,
      total,
      currentPage,
    };
  },
};
</script>

<style lang="scss" scoped>
.skeleton-box {
  display: flex;
  & + .skeleton-box {
    margin-top: 60px;
  }
}
.min-height {
  min-height: 800px;
}
.news-policy-box {
  padding: 29px 20px;
  li {
    width: 100%;
    height: 178px;
    display: flex;
    justify-content: space-between;
    & + li {
      margin-top: 60px;
    }
    &:hover {
      .contact h3 {
        color: #167eb5;
      }
    }
    .image {
      width: 289px;
      height: 178px;
      background: #e5e5e5;
    }
    .contact {
      margin-left: 20px;
      flex: 1;
      overflow: hidden;
      h3 {
        color: #222222;
        font-size: 20px;
        height: 57px;
        font-weight: 700;
        cursor: pointer;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .time-step {
        color: #999999;
        font-size: 15px;
        margin-bottom: 30px;
      }
      .text-contact {
        font-size: 14px;
        color: #222222;
        font-weight: 700;
        line-height: 29px;
        margin-top: -11px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
  .pagination-box {
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }
}
</style>
