<template>
  <div class="main-box">
    <router-view />
  </div>
</template>
<style scoped lang="scss">
.main-box {
  width: 100%;
  // font-family: Alibaba-PuHuiTi-Light;
}
</style>
