import { h } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";
import NewsList from "../views/page/NewsCenter/NewsList.vue";

const routes = [
  {
    path: "/",
    name: "Main",
    // component: Main,
    component: () => import(/* webpackChunkName: "Main" */ "@/views/Main.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        // component: Home,
        component: () =>
          import(/* webpackChunkName: "Home" */ "@/views/page/Home.vue"),
      },
      {
        path: "/About",
        name: "About",
        redirect: "/Introduction",
        // component: AboutUs,
        component: () =>
          import(
            /* webpackChunkName: "Home" */ "@/views/page/AboutUs/AboutUs.vue"
          ),
        children: [
          {
            path: "Introduction",
            name: "Introduction",
            alias: "/Introduction",
            meta: { name: "研究院简介" },
            component: () =>
              import(
                /* webpackChunkName: "Introduction" */ "@/views/page/AboutUs/Introduction.vue"
              ),
          },
          {
            path: "Support",
            name: "Support",
            alias: "/Support",
            component: () =>
              import(
                /* webpackChunkName: "Support" */ "@/views/page/AboutUs/Support.vue"
              ),
          },
          {
            path: "CoreTechnology",
            name: "CoreTechnology",
            alias: "/CoreTechnology",
            component: () =>
              import(
                /* webpackChunkName: "CoreTechnology" */ "@/views/page/AboutUs/CoreTechnology.vue"
              ),
          },
          {
            path: "Honor",
            name: "Honor",
            alias: "/Honor",
            component: () =>
              import(
                /* webpackChunkName: "Honor" */ "@/views/page/AboutUs/Honor.vue"
              ),
          },
          {
            path: "EnterCulture",
            name: "EnterCulture",
            alias: "/EnterCulture",
            component: () =>
              import(
                /* webpackChunkName: "EnterCulture" */ "@/views/page/AboutUs/EnterCulture.vue"
              ),
          },
        ],
      },
      {
        path: "/Business",
        name: "Business",
        redirect: "/Hardware",
        // component: Business,
        component: () =>
          import(
            /* webpackChunkName: "Business" */ "@/views/page/Business/Business.vue"
          ),
        children: [
          {
            path: "Hardware",
            name: "Hardware",
            alias: "/Hardware",
            meta: { name: "硬件信息" },
            component: () =>
              import(
                /* webpackChunkName: "Hardware" */ "@/views/page/Business/Hardware.vue"
              ),
          },
          {
            path: "Software",
            name: "Software",
            alias: "/Software",
            meta: { name: "集成能力建设与解决方案" },
            component: () =>
              import(
                /* webpackChunkName: "Software" */ "@/views/page/Business/Software.vue"
              ),
          },
          {
            path: "Atmospheric",
            name: "Atmospheric",
            alias: "/Atmospheric",
            meta: { name: "大气环境" },
            component: () =>
              import(
                /* webpackChunkName: "Atmospheric" */ "@/views/page/Business/Atmospheric.vue"
              ),
          },
          {
            path: "Weather",
            name: "Weather",
            alias: "/Weather",
            meta: { name: "生态环境数据信息化" },
            component: () =>
              import(
                /* webpackChunkName: "Weather" */ "@/views/page/Business/Weather.vue"
              ),
          },
          {
            path: "Ecotope",
            name: "Ecotope",
            alias: "/Ecotope",
            meta: { name: "生态环境监测" },
            component: () =>
              import(
                /* webpackChunkName: "Ecotope" */ "@/views/page/Business/Ecotope.vue"
              ),
          },
          {
            path: "NewsServer",
            name: "NewsServer",
            alias: "/NewsServer",
            meta: { name: "咨询服务" },
            component: () =>
              import(
                /* webpackChunkName: "NewsServer" */ "@/views/page/Business/NewsServer.vue"
              ),
          },
          {
            path: "SucCase",
            name: "SucCase",
            alias: "/SucCase",
            meta: { name: "政府气象" },
            component: () =>
              import(
                /* webpackChunkName: "SucCase" */ "@/views/page/Business/SucCase.vue"
              ),
          },
          {
            path: "Aviation",
            name: "Aviation",
            alias: "/Aviation",
            meta: { name: "航空案例" },
            component: () =>
              import(
                /* webpackChunkName: "Aviation" */ "@/views/page/Business/Aviation.vue"
              ),
          },
          {
            path: "Ocean",
            name: "Ocean",
            alias: "/Ocean",
            meta: { name: "海洋气象" },
            component: () =>
              import(
                /* webpackChunkName: "Ocean" */ "@/views/page/Business/Ocean.vue"
              ),
          },
          {
            path: "ParticulateMatter",
            name: "ParticulateMatter",
            alias: "/ParticulateMatter",
            meta: { name: "颗粒物、VOCs源解析" },
            component: () =>
              import(
                /* webpackChunkName: "ParticulateMatter" */ "@/views/page/Business/ParticulateMatter.vue"
              ),
          },
          {
            path: "AirQuality",
            name: "AirQuality",
            alias: "/AirQuality",
            meta: { name: "城市空气质量达标规划" },
            component: () =>
              import(
                /* webpackChunkName: "AirQuality" */ "@/views/page/Business/AirQuality.vue"
              ),
          },
          {
            path: "SingleSystem",
            name: "SingleSystem",
            alias: "/SingleSystem",
            meta: { name: "三线一单编制" },
            component: () =>
              import(
                /* webpackChunkName: "SingleSystem" */ "@/views/page/Business/SingleSystem.vue"
              ),
          },
          {
            path: "Comprehensive",
            name: "Comprehensive",
            alias: "/Comprehensive",
            meta: { name: "综合分析报告编制" },
            component: () =>
              import(
                /* webpackChunkName: "Comprehensive" */ "@/views/page/Business/Comprehensive.vue"
              ),
          },
          {
            path: "Performance",
            name: "Performance",
            alias: "/Performance",
            meta: { name: "大气污染防治第三方绩效服务" },
            component: () =>
              import(
                /* webpackChunkName: "Performance" */ "@/views/page/Business/Performance.vue"
              ),
          },
          {
            path: "Assurance",
            name: "Assurance",
            alias: "/Assurance",
            meta: { name: "重大会议活动空气质量保障服务" },
            component: () =>
              import(
                /* webpackChunkName: "Assurance" */ "@/views/page/Business/Assurance.vue"
              ),
          },
        ],
      },
      {
        path: "/Incubate",
        name: "Incubate",
        component: () =>
          import(
            /* webpackChunkName: "Incubate" */ "@/views/page/Incubate/Incubate.vue"
          ),
        // component: Incubate,
      },
      {
        path: "/CompanyDetail",
        name: "CompanyDetail",
        component: () =>
          import(
            /* webpackChunkName: "CompanyDetail" */ "@/views/page/Incubate/CompanyDetail.vue"
          ),
      },
      {
        path: "/NewsCenter",
        name: "NewsCenter",
        redirect: "/Academic",
        component: () =>
          import(
            /* webpackChunkName: "NewsCenter" */ "@/views/page/NewsCenter/NewsCenter.vue"
          ),
        // component: NewsCenter,
        children: [
          {
            path: "Academic",
            name: "Academic",
            alias: "/Academic",
            meta: { name: "学术前沿" },
            component: {
              render: () => h(NewsList, { type: "0" }),
            },
          },
          {
            path: "NewsPolicy",
            name: "NewsPolicy",
            alias: "/NewsPolicy",
            meta: { name: "新闻政策" },
            component: {
              render: () => h(NewsList, { type: "1" }),
            },
          },
          {
            path: "ComCondition",
            name: "ComCondition",
            alias: "/ComCondition",
            meta: { name: "研究院动态" },
            component: {
              render: () => h(NewsList, { type: "2" }),
            },
          },
        ],
      },

      {
        path: "/Recruit",
        name: "Recruit",
        redirect: "/Talents",
        component: () =>
          import(
            /* webpackChunkName: "Recruit" */ "@/views/page/Recruit/Recruit.vue"
          ),
        // component: Recruit,
        children: [
          {
            path: "Talents",
            name: "Talents",
            alias: "/Talents",
            meta: { name: "招贤纳士" },
            component: () =>
              import(
                /* webpackChunkName: "Academic" */ "@/views/page/Recruit/Talents.vue"
              ),
          },
          {
            path: "Training",
            name: "Training",
            alias: "/Training",
            meta: { name: "人才培养" },
            component: () =>
              import(
                /* webpackChunkName: "NewsPolicy" */ "@/views/page/Recruit/Training.vue"
              ),
          },
          {
            path: "Cooperation",
            name: "Cooperation",
            alias: "/Cooperation",
            meta: { name: "合作交流" },
            component: () =>
              import(
                /* webpackChunkName: "Cooperation" */ "@/views/page/Recruit/Cooperation.vue"
              ),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  linkActiveClass: "active",
  history: createWebHashHistory(),
  routes,
});
export default router;
