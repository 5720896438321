import axios from "axios";
import qs from "qs";

export default {
  post: function (url, type, data) {
    return axios.post(url, qs.stringify({ type, data: JSON.stringify(data) }), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  },
};
